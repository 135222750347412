<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：供应商管理视图页面
	开始时间：2021-01-14
	开发人员：刘巍骏
	最后修改：2021-01-14
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="billingview">
    <!-- 供应商操作框 -->
    <div class="billingview_headoperate">
      <!-- 标题 -->
      <div class="head_operateList">
        <ul class="list">
          <li @click="getSupplierListData(0)">
            全部
          </li>
          <li>
            <el-popover placement="bottom-start" width="400" v-model="show_searchBox" trigger="manual">
              <div class="billingview_searchTitle">
                <span>根据以下条件搜索:</span>
              </div>
              <el-form ref="form" :model="searchData" size="mini" label-width="100px">
                <el-form-item label="供应商编码:">
                  <el-input v-model="searchData.supplierCode" size="small" placeholder="请输入供应商编码" clearable></el-input>
                </el-form-item>
                <el-form-item label="供应商名称:">
                  <el-input v-model="searchData.supplierName" size="small" placeholder="请输入供应商名称" clearable></el-input>
                </el-form-item>
                <el-form-item label="供应商邮箱:">
                  <el-input v-model="searchData.supplierEmail" size="small" placeholder="请输入供应商邮箱" clearable></el-input>
                </el-form-item>
                <el-form-item label="联系人姓名:">
                  <el-input v-model="searchData.linkManName" size="small" placeholder="请输入联系人姓名" clearable></el-input>
                </el-form-item>
                <el-form-item>
                  <div class="billingview_searchBtnBox">
                    <el-button type="primary" @click="getSupplierListData(conditionType)">搜索</el-button>
                    <el-button @click="closeSearch">取消</el-button>
                  </div>
                </el-form-item>
              </el-form>
              <span slot="reference" class="billingview_searchBut" @click=" show_searchBox=!show_searchBox">
                查询<i class="el-icon-search"></i>
              </span>
            </el-popover>
          </li>
          <li @click="getSupplierListData(conditionType)">
            刷新<i class="el-icon-refresh-right"></i>
          </li>
        </ul>
      </div>

      <!-- 操作列表 -->
      <div class="head_operateList">
        <ul class="list">
          <li @click="addSupplier">
            <i class="el-icon-plus"></i>新增
          </li>
          <li @click="exportSupplier">
            <i class="el-icon-plus"></i>导入
          </li>
        </ul>
      </div>
    </div>

    <!-- 货品表格 -->
    <div class="billingview_tablewrapper billing_table">
      <el-table id="supplier_table" :data="supplierData" height="100%" border style="width: 100%">
        <!-- 索引 -->
        <el-table-column type="index" fixed width="50">
          <template slot="header" slot-scope="scope">
            <el-tooltip placement="bottom-start" effect="light">
              <div slot="content">
                <ul class="billsList_relvanceTips scollStyle">
                  <li v-for="elem in showListFiledData" v-if="elem.classify == -1">
                    <el-checkbox v-model="elem.is_show">{{elem.name}}</el-checkbox>
                  </li>
                </ul>
              </div>
              <i class="el-icon-setting"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column label="操作" fixed width="80">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline icon" title="修改" @click="updateSupplier(scope.row)"></i>
            <i class="el-icon-delete icon" title="删除" @click="delectSupplier(scope.row)"></i>
          </template>
        </el-table-column>
        <el-table-column prop="supplierNo" label="供应商编号" sortable width="150" v-if="showListFiledArr.includes(0)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="fullName" label="供应商名称" width="220" v-if="showListFiledArr.includes(1)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="supplierCustomerDefaultCost.totalPayment" label="待付款总额" width="120"
          v-if="showListFiledArr.includes(2)" show-overflow-tooltip></el-table-column>
        <!-- <el-table-column prop="simpleName" label="供应商简称" width="220" v-if="showListFiledArr.includes(3)"
          show-overflow-tooltip></el-table-column> -->
        <el-table-column prop="email" label="邮箱" width="220" v-if="showListFiledArr.includes(4)" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="country" label="所在国家" width="150" v-if="showListFiledArr.includes(5)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="province" label="所在省份" width="80" v-if="showListFiledArr.includes(6)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="city" label="所在城市" width="80" v-if="showListFiledArr.includes(7)" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="address" label="详细地址" width="220" v-if="showListFiledArr.includes(8)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="legalName" label="法人姓名" width="80" v-if="showListFiledArr.includes(9)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="website" label="公司网址" width="200" v-if="showListFiledArr.includes(10)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="linkmanName" label="联系人姓名" width="80" v-if="showListFiledArr.includes(11)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="supplierCustomerDefaultCost.taxRage" label="税率(%)" width="80"
          v-if="showListFiledArr.includes(12)" show-overflow-tooltip></el-table-column>
        <el-table-column prop="supplierCustomerDefaultCost.exchangeRage" label="汇率(%)" width="80"
          v-if="showListFiledArr.includes(13)" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="180" v-if="showListFiledArr.includes(14)"
          show-overflow-tooltip></el-table-column>
      </el-table>
    </div>

    <!-- 表格分页 -->
    <div class="billing_tablePage">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="currentPage" :page-sizes="[10, 20, 30, 40,50]" :page-size.sync="currentPageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="totalSize">
      </el-pagination>
    </div>

    <!-- 新增,修改供应商组件 -->
    <el-dialog :title="(operatedState == 0 ?'新增':'修改') + '供应商'" style="height: 100%;overflow-y: hidden;"
      :visible.sync="show_Addsupplier" width="700px" top="6vh" :before-close="close_box" :close-on-click-modal="false"
      append-to-body>
      <!-- 弹框内容-->
      <div class="financeDialog scollStyle">
        <el-tabs v-model="active" type="border-card" style="min-height: 400px;max-height: 583.5px;overflow-y: auto;">
          <el-tab-pane label="基本信息" name="0">
            <!-- 基本信息 -->
            <div>
              <el-form :model="saveAddData" :rules="supplier_rules" label-width="100px" ref="baseInfo" size="mini">
                <!-- 分行显示 -->
                <!-- 分列显示,span的值为24/列数 如:一行一列为24  一行两列为12-->
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="供应商编号:" prop="supplierCode">
                      <el-input v-model="saveAddData.supplierCode" placeholder="请输入供应商编号">
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="供应商名:" prop="supplierName">
                      <el-input v-model="saveAddData.supplierName" placeholder="请输入供应商名">
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="密码设置:">
                      <el-input v-model="saveAddData.passwordLogin" type="password" placeholder="请输入登录密码"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="密码有效期:">
                      <el-date-picker v-model="saveAddData.passwordVaild" @input="getSelectData($event,'passwordVaild')" type="date" placeholder="请选择密码有效期">
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="开启自主下单:">
                      <el-checkbox v-model="saveAddData.isSelfOrder"></el-checkbox>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="自主修改密码:">
                      <el-checkbox v-model="saveAddData.passwordIsEdit"></el-checkbox>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="所属分类:" prop="classfiy">
                      <el-select class="iteminput" v-model="saveAddData.classfiy" size="mini" placeholder="请选择">
                        <el-option v-for="item in SupplierType" :key="item.id" :label="item.name" :value="item.name"
                          v-if="item.is_enable==1" @click.native="getSupplierData(item)"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="企业性制:" prop="nature">
                      <el-input v-model="saveAddData.nature" placeholder="请输入企业性制">
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="国家:" prop="nation">
                      <all-country @getCityName="getCityName" :nation="saveAddData.nation"></all-country>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="省市:" prop="province">
                      <el-input v-model="saveAddData.province" placeholder="请输入省市">
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="城市:" prop="city">
                      <el-input v-model="saveAddData.city" placeholder="请输入城市">
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="公司电话:" prop="supplierTel">
                      <el-input v-model="saveAddData.supplierTel" placeholder="请输入公司电话">
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="公司邮箱:" prop="email">
                      <el-input v-model="saveAddData.email" placeholder="请输入公司邮箱">
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="公司网址:" prop="url">
                      <el-input v-model="saveAddData.url" placeholder="请输入公司网站"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="公司成立日:" prop="date">
                      <el-date-picker v-model="saveAddData.date" @input="getSelectData($event,'date')" type="date" placeholder="选择日期"></el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="地址:" prop="supplierSite">
                      <el-input v-model="saveAddData.supplierSite" type="textarea" :rows="1" placeholder="地址">
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="产品优势:" prop="advantage">
                      <el-input v-model="saveAddData.advantage" type="textarea" :rows="2" placeholder="产品优势"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="备注描述:" prop="supplierRemark">
                      <el-input v-model="saveAddData.supplierRemark" type="textarea" :rows="2" placeholder="备注描述">
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <!-- <el-col :span="16">
                    <el-form-item label="上传附件:" prop="company_telephone">
                      <el-upload class="upload-demo" :action="APi" :headers="headers" :on-success="successData"
                        :before-remove="removeFile" :file-list="ImgArr">
                        <el-button size="small" type="primary">添加附件</el-button>
                      </el-upload>
                    </el-form-item>
                  </el-col> -->
                  <el-col :span="8">
                    <el-form-item label="启用:" prop="company_telephone">
                      <el-checkbox v-model="saveAddData.state"></el-checkbox>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </el-tab-pane>
          <el-tab-pane label="期初信息" name="1">
            <!-- 期初信息 -->
            <div>
              <el-form label-width="120px" :rules="supplier_rules" ref="person_ruleform" size="mini">
                <!-- 分行显示 -->
                <!-- 分列显示,span的值为24/列数 如:一行一列为24  一行两列为12-->
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="默认税率:" prop="taxRate">
                      <el-input v-model="saveAddData.taxRate" placeholder="请输入默认税率" style="width: 80%;">
                      </el-input><span style="padding-left: 10px;">%</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="默认汇率:" prop="exchangeRage">
                      <el-input v-model="saveAddData.exchangeRage" placeholder="请输入默认汇率" style="width: 80%;">
                      </el-input><span style="padding-left: 10px;">%</span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="结算币种:" prop="currency">
                      <el-select class="iteminput" v-model="saveAddData.currency" size="mini" placeholder="请选择">
                        <el-option v-for="item in FinanceCurrency" :key="item.id" :label="item.name" :value="item.id"
                          v-if="item.is_enable==1" @click.native="getCurrencyData(item)"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="期初应付款:" prop="due">
                      <el-input v-model="saveAddData.due" :disabled="operatedState == 1" placeholder="请输入期初应付款">
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="信任额度:" prop="advance">
                      <el-input v-model="saveAddData.advance" placeholder="请输入信任额度">
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="信任时间:" prop="trustDate">
                      <el-input v-model="saveAddData.trustDate" placeholder="请输入信任时间">
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="应付款:" prop="total_payment">
                      <el-input v-model="saveAddData.total_payment" :disabled="true" placeholder="请输入应付款">
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="价格是否含税:" prop="isRate">
                      <el-select class="iteminput" v-model="saveAddData.isRate" size="small" placeholder="请选择">
                        <el-option v-for="item in isRate" :key="item.id" :label="item.name" :value="item.id"
                          @click.native="getIsRateData(item)"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="对账单结存时间:" prop="balanceTime">
                      每月
                      <el-input-number v-model="saveAddData.balanceTime" controls-position="right" :min="0" :max="31"></el-input-number>
                      号
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </el-tab-pane>
          <el-tab-pane label="财务信息" name="2">
            <div>
              <el-form label-width="100px" :rules="supplier_rules" ref="tool_ruleform" size="mini">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="售价等级:" prop="grade">
                      <el-select class="iteminput" v-model="saveAddData.grade" size="mini" placeholder="请选择">
                        <el-option v-for="item in MemberShip" :key="item.id" :label="item.name" :value="item.id"
                          @click.native="getMemberData(item)"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="结算方式:" prop="clearingForm">
                      <el-select class="iteminput" v-model="saveAddData.clearingForm" size="mini" placeholder="请选择">
                        <el-option v-for="item in OrderSettlement" :key="item.id" :label="item.name" :value="item.id"
                          @click.native="getSettlementData(item)"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="开户行:" prop="bank">
                      <el-input v-model="saveAddData.bank" placeholder="请输入开户行">
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="银行帐号:" prop="bankAccount">
                      <el-input v-model="saveAddData.bankAccount" placeholder="请输入银行帐号"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="信用代码:" prop="creditCode">
                      <el-input v-model="saveAddData.creditCode" placeholder="请输入信用代码">
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="税务代码:" prop="taxationCode">
                      <el-input v-model="saveAddData.taxationCode" placeholder="请输入税务代码">
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </el-tab-pane>
          <el-tab-pane label="联系人信息" :rules="supplier_rules" name="3">
            <div>
              <el-form label-width="80px" ref="tool_ruleform" size="mini">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="姓名:" prop="name">
                      <el-input v-model="saveAddData.name" placeholder="请输入姓名">
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="职务:" prop="duty">
                      <el-input v-model="saveAddData.duty" placeholder="请输入职务">
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="座机号:" prop="landline">
                      <el-input v-model="saveAddData.landline" placeholder="请输入座机号">
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="手机号:" prop="phone">
                      <el-input v-model="saveAddData.phone" placeholder="请输入手机号">
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="微信:" prop="WeChat">
                      <el-input v-model="saveAddData.WeChat" placeholder="请输入微信">
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="QQ:" prop="QQ">
                      <el-input v-model="saveAddData.QQ" oninput="this.value=this.value.replace(/[^0-9]/g,'')"
                        placeholder="请输入QQ">
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="信仰:" prop="belief">
                      <el-input v-model="saveAddData.belief" placeholder="请输入信仰">
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="生日:" prop="birthday">
                      <el-date-picker v-model="saveAddData.birthday" @input="getSelectData($event,'birthday')" type="date" placeholder="选择日期"></el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="地址:" prop="site">
                      <el-input v-model="saveAddData.site" type="textarea" :rows="2" placeholder="详细地址"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="备注:" prop="remark">
                      <el-input v-model="saveAddData.remark" type="textarea" :rows="2" placeholder="备注"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- 弹框操作按钮-->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="close_box">取 消</el-button><!--  -->
        <el-button size="small" type="primary" @click="saveSupplierData">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 供应商导入组件 -->
    <supplierExport :showSupplierExportBox="showSupplierExportBox" v-if="showSupplierExportBox"></supplierExport>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  // 导入组件
  import allCountry from '@/components/commComponent/new_country'; //国家组件
  import supplierExport from './supplier_export.vue'; //供应商导入组件

  // 导入请求接口
  import api from '@/network/system/dictionary'; //数据字典相关接口
  import req from '@/network/datum/supplier'; //供应商相关接口

  export default {
    name: 'supplierView',
    props: {
      //产品分类数据
      selClassifyId: {
        type: Number,
        default: 0
      },
      //是否执行查询
      is_execute: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        //条件查询相关
        searchData: { //查询条件
          supplierCode: '', //供应商编码
          supplierName: '', //供应商名称
          supplierEmail: '', //供应商名称
          linkManName: '', //联系人名称
        },
        conditionType: 0, //当前查询的条件(0:全部 其他:分类)

        //界面数据
        active: '0', //选项卡下标
        operatedState: 0, //判断是修改还是新增
        supplierData: [], //供应商数据
        saveAddData: { //供应商信息
          //基本信息
          id: '', //供应商Id
          supplierCode: '', //供应商编号
          supplierName: '', //供应商名称
          isSelOrder: '', //是否自主下单
          passwordLogin: '', //登录密码
          passwordIsEdit: '', //密码是否可编辑(0:否  1:是)
          passwordVaild: '', //密码有效期
          classfiy: '', //所属分类名称
          supplierId: '', //供应商分类Id
          nature: '', //企业性质
          nation: '', //国家
          province: '', //省份
          city: '', //城市
          supplierSite: '', //地址
          supplierTel: '', //公司电话
          email: '/', //公司邮箱
          url: '', //公司网址
          date: '', //公司成立日期
          advantage: '', //产品优势
          supplierRemark: '', //备注描述
          accessory: '', //相关附件
          state: true, //状态
          //期初信息
          taxRate: '', //税率
          isRate:-1,//价格是否含税
          exchangeRage: '', //汇率
          balanceTime: '',//对账结存时间
          currency: '', //币种
          currencyId: '', //币种id
          due: '', //期初应付款
          advance: '', //信任额度
          trustDate: '', //信任时间
          //财务信息
          grade: '', //等级
          gradeId: '', //等级Id
          clearingForm: '', //结算方式
          clearingFormId: '', //结算方式Id
          bank: '', //开户行
          bankAccount: '', //银行账号
          creditCode: '', //信用代码
          taxationCode: '', //税务代码
          //联系人信息
          name: '', //联系人姓名
          duty: '', //职务
          landline: '', //座机号
          phone: '', //手机号
          WeChat: '', //微信号码
          QQ: '', //QQ号码
          birthday: '', //生日
          belief: '', //信仰
          site: '', //地址
          remark: '', //备注
        },

        // 辅助数据
        supplier_rules: {}, //表单验证规则
        showListFiledData: [], //列表可显示的字段
        showListFiledArr: [], //列表需要显示的

        //控制开关
        show_searchBox: false, //是否显示搜索
        isEnable: false, //是否启用分页
        show_Addsupplier: false, //控制新增修改弹框
        show_manageType: false, //控制管理类型
        showSupplierExportBox: false, //控制导入弹框显示隐藏

        //文件相关
        //请求头
        headers: {
          token: '',
        },
        APi: baseUrl + '/system/dictionary/uploadAccessory',
        ImgArr: [], //文件列表
        imgUrlArr: [], //附件地址

        // 分页相关
        currentPage: 1, //页码
        currentPageSize: 20, //页面显示数据条数
        totalSize: 0, //总页数

        //loading框
        loading: '', //加载loading
      }
    },
    computed: {
      ...mapState({
        FinanceCurrency: state => state.system.FinanceCurrency, //交易币种
        OrderSettlement: state => state.system.OrderSettlement, //结算方式
        MemberShip: state => state.system.MemberShip, //会员等级
        SupplierType: state => state.system.SupplierType, //供应商分类
        isRate: state => state.system.isRate, //是否含税
        userPowerInfo: state => state.user.userPowerInfo, //用户权限
        supplierListFileData: state => state.bill_list_config.supplierListFileData, //供应商列表可显示字段
      })
    },
    watch: {
      /* 监听点击分类 */
      is_execute(newVal) {
        //判断是查询的全部还是分类
        if (this.selClassifyId == 0) {
          this.conditionType = 0;
        } else {
          this.conditionType = 1;
        }
        //查询货品列表数据
        this.getSupplierListData(this.conditionType);
      },
      /* 监听控制新增弹框数据发生改变 */
      show_addBox(newVal) {
        if (!newVal) {
          //关闭时默认请求一次数据
          this.getSupplierListData(this.conditionType);
        } else {
          //打开时默认初始化一次vuex数据
          this.INIT_PRODUCT_DATA();
        }
      },
      // 监听显示列表数据发生变化
      showListFiledData: {
        handler(newVal) {
          this.getCurShowFiledArr();
        },
        deep: true
      },
    },
    created() {
      //初始化vuex相关数据
      this.initVuexData();
      //初始化表单验证
      this.initRules();
    },
    mounted() {
      //默认获取全部供应商数据
      this.getSupplierListData(0);
      //初始化列表显示字段数据
      this.initListShowFiledData();
    },
    methods: {
      ...mapMutations([]),
      ...mapActions([
        "getFinanceCurrency", //获取交易币种信息
        "getAllSupplierSettlement", //获取结算方式
        "getAllSupplierMember", //会员等级
        "getSupplierClassfiy", //供应商类别
        'getUserPowerInfo', //获取用户操作权限
      ]),

      /* 初始化界面所需vuex相关数据 */
      initVuexData() {
        //交易币种
        if (!this.FinanceCurrency.length > 0) {
          this.getFinanceCurrency();
        }
        // 会员等级
        if (!this.FinanceCurrency.length > 0) {
          this.getAllSupplierMember();
        }
        // 结算方式
        if (!this.OrderSettlement.length > 0) {
          this.getAllSupplierSettlement();
        }
        //供应商分类
        if (!this.SupplierType.length > 0) {
          this.getSupplierClassfiy();
        }
      },

      /* 获取供应商列表数据type(0:全部  1:分类) */
      async getSupplierListData(type) {
        //判断用户权限(需优化  这里先这样写  不知道为什么await没有用)
        if (this.userPowerInfo.length == 0) {
          await this.getUserPowerInfo();
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(21, 3, 1, '查询')) {
            this.supplierData = []
            return
          }
        } else {
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(21, 3, 1, '查询')) {
            this.supplierData = []
            return
          }
        }
        //将查询类别存入
        this.conditionType = this.selClassifyId;
        //定义查询公共数据
        let data = {
          pageIndex: this.currentPage, //当前页
          pageSize: this.currentPageSize, //页面大小
          deleteFlag: 0, //删除标识pageIndex
        }

        //判断分类查询条件
        if (this.conditionType != 0) {
          data.supplierType = this.conditionType;
        }

        //判断其他查询条件
        //供应商编码
        if (this.searchData.supplierCode != '') {
          data.supplierCode = this.searchData.supplierCode;
        }
        //供应商名称
        if (this.searchData.supplierName != '') {
          data.supplierName = this.searchData.supplierName;
        }
        //供应商邮箱
        if (this.searchData.supplierEmail != '') {
          data.supplierEmail = this.searchData.supplierEmail;
        }
        //联系人姓名
        if (this.searchData.linkManName != '') {
          data.linkmanName = this.searchData.linkManName;
        }

        //关闭高级查询弹框
        this.show_searchBox = false;

        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#supplier_body", 4, '供应商列表信息获取中,请稍候...');
        //发送请求
        req.findSupplierByCondition(data).then(res => {
          //关闭loading框
          this.loading.close();
          //判断接口返回数据
          if (res.code == 200) {
            //获取当前列表数据
            this.supplierData = res.data.rows;
            //获取总条数
            this.totalSize = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 显示新增弹框 */
      addSupplier() {
        //判断是否有新增权限
        if (this.commonJsExtend.isHaveThePower(21, 3, 2, '新增')) {
          return
        }
        //初始化供应商对象
        this.saveAddData = Object.assign({}, this.$options.data().saveAddData);
        //将判断条件修改为新增
        this.operatedState = 0;
        //显示新增弹框
        this.show_Addsupplier = true;
        //初始tab页
        this.active = '0';
      },

      /* 修改供应商 */
      updateSupplier(data) {
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(21, 3, 4, '修改')) {
          return
        }

        // 基本信息
        this.saveAddData.id = data.id; //供应商id
        this.saveAddData.supplierCode = data.supplierNo //供应商编码
        this.saveAddData.supplierId = data.typeId //供应商分类Id
        this.saveAddData.classfiy = data.typeName || ''; //供应商分类名称
        this.saveAddData.province = data.province //省份
        this.saveAddData.city = data.city //城市
        this.saveAddData.supplierSite = data.address //地址
        this.saveAddData.email = data.email //邮箱
        this.saveAddData.nature = data.companyNature //企业性质
        this.saveAddData.supplierName = data.fullName //供应商名称
        this.saveAddData.supplierTel = data.phone //公司电话
        this.saveAddData.clearingFormId = data.settlementId //结算方式Id
        this.saveAddData.clearingForm = data.settlementName; //结算方式
        this.saveAddData.date = data.setupDate //成立日期
        this.saveAddData.advantage = data.superiority //产品优势
        this.saveAddData.url = data.website //公司网址
        this.saveAddData.supplierRemark = data.remark //备注描述
        this.saveAddData.state = data.isSupplier == 1 ? true : false;
        this.saveAddData.isSelOrder = data.isSelOrder == 1 ? true : false;//自主下单
        this.saveAddData.passwordLogin = data.passwordLogin;//登录密码
        this.saveAddData.passwordIsEdit = data.passwordIsEdit == 1 ? true : false;//密码是否可编辑
        this.saveAddData.passwordVaild = data.passwordVaild;//密码有效期
        //期初信息
        this.saveAddData.currencyId = data.currencyType //币种id
        this.saveAddData.currency = data.supplierCustomerDefaultCost == null ? '' : data
          .supplierCustomerDefaultCost.currencyName; //币种名称
        this.saveAddData.taxRate = data.supplierCustomerDefaultCost == null ? '' : data
          .supplierCustomerDefaultCost.taxRage //默认税率
        this.saveAddData.exchangeRage = data.supplierCustomerDefaultCost == null ? '' : data
          .supplierCustomerDefaultCost.exchangeRage //默认汇率
        this.saveAddData.isRate = data.supplierCustomerDefaultCost == null ? '' : data
          .supplierCustomerDefaultCost.isRate //价格是否含税
        this.saveAddData.balanceTime = data.supplierCustomerDefaultCost == null ? '' : data
          .supplierCustomerDefaultCost.balanceTime; //对账结存时间
        this.saveAddData.advance = data.supplierCustomerTrustCost == null ? '' : data
          .supplierCustomerTrustCost.reputationLimit //信任额度
        this.saveAddData.trustDate = data.supplierCustomerTrustCost == null ? '' : data
          .supplierCustomerTrustCost.reputationTime //信任时间
        this.saveAddData.due = data.supplierCustomerDefaultCost == null ? '' : data
          .supplierCustomerDefaultCost.waitGathering //期初应付款
        this.saveAddData.total_payment = data.supplierCustomerDefaultCost == null ? '' : data
          .supplierCustomerDefaultCost.totalPayment; //待付款总额
        //财务信息
        this.saveAddData.bankAccount = data.bankAccount //银行账号
        this.saveAddData.creditCode = data.creditCode //信用代码
        this.saveAddData.bank = data.openingBank //开户行
        this.saveAddData.taxationCode = data.taxCode //税务代码
        // 联系人信息
        this.saveAddData.name = data.linkmanName //联系人姓名
        this.saveAddData.site = data.linkmanAddress //联系人地址
        this.saveAddData.belief = data.linkmanBelief //联系人信仰
        this.saveAddData.birthday = data.linkmanBirthday //联系人生日
        this.saveAddData.duty = data.linkmanDuty //联系人职务
        this.saveAddData.landline = data.linkmanPhone //座机号
        this.saveAddData.phone = data.mobile //手机号
        this.saveAddData.gradeId = data.priceLevel //等级
        this.saveAddData.grade = data.priceLevelName; //等级名称
        this.saveAddData.QQ = data.qq //QQ号码
        this.saveAddData.WeChat = data.wechat //联系人微信
        this.saveAddData.remark = data.linkmanRemark //联系人备注
        this.$nextTick(() => {
          this.saveAddData.nation = data.country //国家
        })
        //处理附件信息
        if (data.filePath != null) {
          let imgarr = data.filePath.split(",") //相关附件
          if (imgarr[0] != '') {
            imgarr.forEach((item, index) => {
              this.imgUrlArr.push(item)
              let foo = item.split("@@@")
              let imgobj = {}
              let imgurl = baseUrl + foo[0]
              imgobj.name = foo[1]
              imgobj.url = imgurl
              this.ImgArr.push(imgobj)
            })
          }
        }

        //显示修改弹框
        this.show_Addsupplier = true;
        //将判断条件修改为修改
        this.operatedState = 1;
        //初始tab页
        this.active = '0';
      },

      /* 保存供应商信息 */
      /* 新增供应商 */
      saveSupplierData() {
        this.$refs['baseInfo'].validate(valid => {
          if (valid) {
            if (this.operatedState == 0) {
              var data = {
                //基本信息
                supplier_no: this.saveAddData.supplierCode, //供应商编码
                full_name: this.saveAddData.supplierName, //供应商名称
                country: this.saveAddData.nation, //国家
                province: this.saveAddData.province, //省份
                city: this.saveAddData.city, //城市
                type_id: this.saveAddData.supplierId, //供应商Id
                address: this.saveAddData.supplierSite, //地址
                company_nature: this.saveAddData.nature, //企业性质
                is_self_order:this.saveAddData.isSelOrder ? 1 : 0,//是否自主下单
                password_login:this.saveAddData.passwordLogin,//登录密码
                password_is_edit:this.saveAddData.passwordIsEdit ? 1 : 0,//密码是否可编辑
                password_vaild:this.saveAddData.passwordVaild,//密码有效期
                phone: this.saveAddData.supplierTel, //公司电话
                price_level: this.saveAddData.gradeId, //等级
                setup_date: this.saveAddData.date, //成立日期
                superiority: this.saveAddData.advantage, //产品优势
                file_path: this.imgUrlArr.join(","), //相关附件
                is_supplier: this.saveAddData.state ? 1 : 0, //是否在供应
                website: this.saveAddData.url, //公司网址
                remark: this.saveAddData.supplierRemark, //备注描述
                //信誉信息
                reputation_limit: this.saveAddData.advance, //信任额度
                reputation_time: this.saveAddData.trustDate, //信任时间
                wait_gathering: this.saveAddData.due, //期初应付款
                wait_payment: '', //待付款
                //财务信息
                tax_rage: this.saveAddData.taxRate, //默认税率
                exchange_rage: this.saveAddData.exchangeRage, //默认汇率
                is_rate: this.saveAddData.isRate, //价格是否含税
                balance_time: this.saveAddData.balanceTime, //对账结存时间
                bank_account: this.saveAddData.bankAccount, //银行账号
                credit_code: this.saveAddData.creditCode, //信用代码
                currency_type: this.saveAddData.currencyId, //币种id
                opening_bank: this.saveAddData.bank, //开户行
                settlement_id: this.saveAddData.clearingFormId, //结算方式id
                tax_code: this.saveAddData.taxationCode, //税务代码
                //联系人信息
                email: this.saveAddData.email, //邮箱
                linkman_name: this.saveAddData.name, //联系人名称
                wechat: this.saveAddData.WeChat, //联系人微信
                linkman_address: this.saveAddData.site, //联系人地址
                linkman_belief: this.saveAddData.belief, //联系人信仰
                linkman_phone: this.saveAddData.landline, //座机号
                mobile: this.saveAddData.phone, //手机号
                linkman_birthday: this.saveAddData.birthday, //联系人生日
                linkman_duty: this.saveAddData.duty, //联系人职务
                linkman_remark: this.saveAddData.remark, //联系人备注
                qq: this.saveAddData.QQ, //QQ号码
              }
              //发送请求
              req.addSupplierAllData(data).then(res => {
                if (res.code == 200) {
                  this.$message({
                    type: 'success',
                    message: '添加供应商成功',
                    duration: this.elDuration
                  })
                  //关闭弹框
                  this.close_box();
                  //获取列表信息
                  this.getSupplierListData(this.conditionType);
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
            } else {
              var data = {
                //基本信息
                id: this.saveAddData.id, //id
                supplier_no: this.saveAddData.supplierCode, //供应商编码
                full_name: this.saveAddData.supplierName, //供应商名称
                country: this.saveAddData.nation, //国家
                province: this.saveAddData.province, //省份
                city: this.saveAddData.city, //城市
                type_id: this.saveAddData.supplierId, //供应商Id
                address: this.saveAddData.supplierSite, //地址
                company_nature: this.saveAddData.nature, //企业性质
                is_self_order:this.saveAddData.isSelOrder ? 1 : 0,//是否自主下单
                password_login:this.saveAddData.passwordLogin,//登录密码
                password_is_edit:this.saveAddData.passwordIsEdit ? 1 : 0,//密码是否可编辑
                password_vaild:this.saveAddData.passwordVaild,//密码有效期
                phone: this.saveAddData.supplierTel, //公司电话
                price_level: this.saveAddData.gradeId, //等级
                setup_date: this.saveAddData.date, //成立日期
                superiority: this.saveAddData.advantage, //产品优势
                file_path: this.imgUrlArr.join(","), //相关附件
                is_supplier: this.saveAddData.state ? 1 : 0, //是否在供应
                website: this.saveAddData.url, //公司网址
                remark: this.saveAddData.supplierRemark, //备注描述
                //期初信息
                reputation_limit: this.saveAddData.advance, //信任额度
                reputation_time: this.saveAddData.trustDate, //信任时间
                wait_gathering: this.saveAddData.due, //应付款
                wait_payment: '', //待付款
                //财务信息
                tax_rage: this.saveAddData.taxRate, //默认税率
                exchange_rage: this.saveAddData.exchangeRage, //默认汇率
                is_rate: this.saveAddData.isRate, //价格是否含税
                balance_time: this.saveAddData.balanceTime, //对账结存时间
                bank_account: this.saveAddData.bankAccount, //银行账号
                credit_code: this.saveAddData.creditCode, //信用代码
                currency_type: this.saveAddData.currencyId, //币种id
                opening_bank: this.saveAddData.bank, //开户行
                settlement_id: this.saveAddData.clearingFormId, //结算方式id
                tax_code: this.saveAddData.taxationCode, //税务代码
                //联系人信息
                email: this.saveAddData.email, //邮箱
                linkman_name: this.saveAddData.name, //联系人名称
                wechat: this.saveAddData.WeChat, //联系人微信
                linkman_address: this.saveAddData.site, //联系人地址
                linkman_belief: this.saveAddData.belief, //联系人信仰
                linkman_phone: this.saveAddData.landline, //座机号
                mobile: this.saveAddData.phone, //手机号
                linkman_birthday: this.saveAddData.birthday, //联系人生日
                linkman_duty: this.saveAddData.duty, //联系人职务
                linkman_remark: this.saveAddData.remark, //联系人备注
                qq: this.saveAddData.QQ, //QQ号码
              }
              //发送请求
              req.updateSupplierAllData(data).then(res => {
                if (res.code == 200) {
                  this.$message({
                    type: 'success',
                    message: '修改成功',
                    duration: this.elDuration
                  })
                  //关闭弹框
                  this.close_box();
                  //获取列表信息
                  this.getSupplierListData(this.conditionType);
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
            }
          }
        })
      },

      /* 删除供应商 */
      delectSupplier(data) {
        //判断是否有删除权限
        if (this.commonJsExtend.isHaveThePower(21, 3, 3, '删除')) {
          return
        }
        this.$confirm('您确定要删除该供应商吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          req.updateSupplierDeleteFlag({
            supplierId: data.id
          }).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '删除成功',
                duration: this.elDuration
              })
              //获取列表信息
              this.getSupplierListData(this.conditionType);
            } else {
              this.$message({
                type: 'error',
                message: res.message,
              })
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
      },

      /* 获取日期选择弹框数据*/
      getSelectData(date, filed) {
        //将选择后的日期格式进行转换,不转换存的是国际标准时间
        this.saveAddData[filed] = this.commonJsExtend.getDateTime(date, 0);
        //强制刷新数据($set不能解决,暂用这种方式)
        this.$forceUpdate();
      },

      /* 分页大小发生改变 */
      handleSizeChange(val) {
        //获取当前页面大小
        this.currentPageSize = val;
        //获取列表数据
        this.getSupplierListData(this.conditionType);
      },

      /* 当前页面发生改变 */
      handleCurrentChange(val) {
        //获取当前页下标
        this.currentPage = val;
        //获取列表数据
        this.getSupplierListData(this.conditionType);
      },

      /* 获取选择结算方式Id */
      getSettlementData(data) {
        this.saveAddData.clearingFormId = data.id
      },

      /* 获取会员等级Id*/
      getMemberData(data) {
        this.saveAddData.gradeId = data.id
      },

      /* 获取选择币种信息 */
      getCurrencyData(data) {
        this.saveAddData.currencyId = data.id
      },

      /* 获取供应商数据 */
      getSupplierData(data) {
        this.saveAddData.supplierId = data.id
      },

      /* 单据页面大小信息改变 */
      billsPageChange(val) {
        //获取单据当前页信息
        this.billsPageData.currentPageSize = val;
        //获取数据
        this.getBillsData(this.currentBillsProduct, this.currentBillsType);
      },

      /* 单据列表当前页信息改变 */
      getCurrentPage(val) {
        //获取单据当前页信息
        this.billsPageData.currentPage = val;
        //获取数据
        this.getBillsData(this.currentBillsProduct, this.currentBillsType);
      },

      /* 导入供应商 */
      exportSupplier() {
        //判断是否有导入权限
        if (this.commonJsExtend.isHaveThePower(21, 3, 6, '导入')) {
          return
        }
        this.showSupplierExportBox = true;
      },

      /* 上传成功时触发 */
      successData(response, file, fileList) {
        this.imgUrlArr.push(response.data)
      },

      /* 删除数据 */
      removeFile(file, fileList) {
        //删除传递到后端数组中的数据
        this.imgUrlArr.forEach((item, index) => {
          if (file.hasOwnProperty("url")) {
            let url = item.substr(0, item.indexOf("@"))
            let imgurl = baseUrl + url
            if (imgurl == file.url) {
              this.imgUrlArr.splice(index, 1)
            }
          } else {
            if (item == file.response.data) {
              this.imgUrlArr.splice(index, 1)
            }
          }
        })
      },

      /* 获取城市名称 */
      getCityName(data) {
        // console.log(data);
        this.saveAddData.nation = data
      },

      /*初始化列表显示字段数据 */
      initListShowFiledData() {
		  //获取界面显示数组
		  this.showListFiledArr = this.commonJsExtend.initListShowFiledData('supplierListArr', this.supplierListFileData)
		    .showListFiledArr;
		  //获取控制数组
		  this.showListFiledData = this.commonJsExtend.initListShowFiledData('supplierListArr', this.supplierListFileData)
		    .showListFiledData;
      },

      /* 获取当前需要显示的列表字段 */
      getCurShowFiledArr() {
        //将数据存入cookie
        this.showListFiledArr = this.commonJsExtend.getCurShowFiledArr('supplierListArr',this.showListFiledData);
      },

      /* 关闭搜索 */
      closeSearch() {
        this.show_searchBox = false
        Object.assign(this.$data.searchData, this.$options.data().searchData)
      },

      /* 关闭新增,修改弹框 */
      close_box() {
        this.show_Addsupplier = false;
      },
      
      /* 关闭导入弹框 */
      closeImportBox(){
        this.showSupplierExportBox = false;
      },

      /* 初始化表单验证信息 */
      initRules() {
        this.supplier_rules = {
          supplierCode: [{ //规则1
            required: true, //是否必填
            message: '请输入编码', //该规则错误信息提示
            trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
          }, ],
          supplierName: [{
            required: true, //是否必填
            message: '请输入名称', //该规则错误信息提示
            trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
          }, ],
          classfiy: [{
            required: true, //是否必填
            message: '请输入名称', //该规则错误信息提示
            trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
          }, ],
          province: [{
            required: true, //是否必填
            message: '请输入省份', //该规则错误信息提示
            trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
          }, ],
          city: [{
            required: true, //是否必填
            message: '请输入城市', //该规则错误信息提示
            trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
          }],
          email: [{
            required: true, //是否必填
            message: '请输入邮箱', //该规则错误信息提示
            trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
          }, ]
        }
      },
    },
    components: {
      allCountry,
      supplierExport,
    }
  }
</script>
<style lang="less" scoped>
  .prodoctName {
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: default;
  }

  .rotateX {
    transform: rotateX(180deg);
  }

  .billingDetailBox {
    height: 45vh;
  }

  // 单据分页弹框
  .billsPageBox {
    padding: 5px 0;
    // border: 1px solid black;
  }
</style>
