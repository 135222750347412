<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：供应商管理主页面
	开始时间：2021-01-14
	开发人员：刘巍骏
	最后修改：2021-01-14
	备注说明：如需修改请联系开发人员
-->
<template>
  <!-- 供应商管理 -->
  <div class="AppTreeTableContent" id="supplier_body">
    <!-- 资料左边列表 -->
    <div class="AppLeftBox">
      <!-- 供应商分类 -->
      <div class="commodityLeft_product">
        <!-- 供应商分类列表 -->
        <div class="commodityLeft_list">
          <div class="commodity_btn" @click="getClassifyData(0)">
            <i class="el-icon-menu"></i>
            <span>全部分类</span>
          </div>
          <div>
            <el-tree :data="supplierClassfiy" :props="defaultProps" node-key="id" ref="tree" default-expand-all
              :expand-on-click-node="false" @current-change="getClassifyData">
              <span class="custom-tree-node" slot-scope="{ node, supplierClassfiy}">
                <span>{{ node.label }}</span>
                <span>
                  <el-button type="text" size="mini">
                    <i class="el-icon-more"></i>
                  </el-button>
                </span>
              </span>
            </el-tree>
          </div>
        </div>
      </div>
    </div>

    <!-- 资料分类拉伸 -->
    <div class="AppHandelBox"></div>

    <!-- 资料操作内容 -->
    <div class="AppRightBox">
      <commodity-view ref="commodityIndex" :selClassifyId="selClassifyId" :is_execute="is_execute"></commodity-view>
    </div>
  </div>
</template>

<script>
  //导入请求
  import api from '@/network/system/dictionary';//数据字典接口
  import req from '@/network/datum/supplier';//供应商接口

  //导入组件
  import CommodityView from './supplier_view.vue';//供应商列表组件

  export default {
    name: 'commoditySupplierIndex',
    data() {
      return {
        //页面数据
        supplierClassfiy: [],//供应商分类数据
        selClassifyId:0,//当前选择的产品分类数据

        //改变tree属性名
        defaultProps: {
          children: 'children',
          label: 'name'
        },

        // 辅助数据
        is_execute:false,//是否执行查询(用于每次点击时都执行一次查询)

        // loading框
        loading: '', //loading框加载
      }
    },
    created() {
      //获取供应商分类列表
      this.getSupplierClassfiy();
    },
    mounted() {
      // 初始化分类栏目拉伸
      this.commonJsExtend.getDunamicWidth();
    },
    methods: {
      /* 查询供应商分类列表 */
      getSupplierClassfiy() {
        //清空分类数据
        this.supplierClassfiy = [];
        //获取分类列表
        api.supplierDict.getSupplierClassfiy().then(res => {
          if (!!res) {
            res.data.forEach(item => {
              //判断不启用的不显示
              if (item.is_enable == 1) {
                this.supplierClassfiy.push(item)
              }
            })
          }
        })
        console.log();
      },

      /* 获取当前分类数据 */
      getClassifyData(data){
        //只要发生该时间就进行一次查询
        this.is_execute  = !this.is_execute;
        //判断查询类型
        if(data == 0){
          this.selClassifyId = 0;
        }else{
          this.selClassifyId = data.id;
        }
      },
    },
    components: {
      CommodityView,
    }
  }
</script>

<style lang="less">
  .AppTreeTableContent {
    height: 100%;
    display: flex;

    // border: 1px solid black;
    // 左边分类框
    .AppLeftBox {

      // padding: 10px 0;
      // 商品分类
      .commodityLeft_product {
        width: calc(100% - 10px);
        padding: 10px 0 10px 10px;

        // border: 1px solid black;
        .commodityLeft_head {
          //头部提示信息
          height: 50px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 10px;
          background-color: @form_bgColer;

          // border: 1px solid black;
          .title {
            //标题
            font-weight: bold;
            // border: 1px solid black;
          }

          .icon {
            //图标
            cursor: pointer;
            transform: rotate(90deg);
          }

          .icon:hover {
            color: @theme;
          }
        }
        
        // 列表
        .commodityLeft_list {
          height: calc(100% - 10px);
          overflow: auto;
          padding: 10px;

          // border: 1px solid black;
          .commodity_btn {
            line-height: 28px;
            height: 28px;
            margin-left: 0px;
            font-size: 14px;
            cursor: pointer;
            color: #606266;
          }

          .commodity_btn:hover {
            background-color: #F2F6FC;
          }

          .custom-tree-node {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            // border: 1px solid red;
            button {
              color: white;
            }
          }

          .custom-tree-node:hover {
            button {
              color: @theme;
            }
          }
        }

        .commodityLeft_classfi {
          height: calc(100% - 72px);
          // background: @assist;
          padding: 10px;
          display: flex;

          // border: 1px solid red;
          .classfi_ul {
            width: 100%;
            cursor: pointer;

            // border: 1px solid black;
            li {
              padding: 10px 0;
              text-align: center;
              // border: 1px solid black;
            }

            li:hover {
              color: @theme;
            }
          }
        }
      }

      // 管理分类
    }

    .commodityHandelBox {}
  }
</style>

<!-- 导入单据公共样式 -->
<style lang="less" src="@/less/Comm/comm_billing.less"></style>
